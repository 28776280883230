import React from 'react';
import Features from './Features';
import Advantages from './Advantages';
import Footer from '../Footer';
import { FAQ } from '../FAQ/faqs';



function Accounting() {
  return (
    <>
<Features docname="Accounting and Finance"/>
<Advantages docname="Accounting and Finance"/>
<FAQ category="About Accxchange"/>
<Footer segment="Smarty Home"/>
         
    </>
  );
}

export default Accounting;
