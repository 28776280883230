import React from 'react';
import Features from './Features';
import Advantages from './Advantages';
import { FAQ } from '../FAQ/faqs';
import Footer from '../Footer';




function Ecommerce() {
  return (
    <>
<Features docname="eCommerce and Online Selling"/>
<Advantages docname="eCommerce and Online Selling"/>
<FAQ category="About Accxchange"/>
<Footer segment="Smarty Home"/>
    </>
  );
}

export default Ecommerce;
