import React from 'react';
import Features from './Features';
import Advantages from './Advantages';
import { FAQ } from '../FAQ/faqs';
import Footer from '../Footer';





function ExpenseTravelManagement() {
  return (
    <>
<Features docname="Expense and Travel management"/>
<Advantages docname="Expense and Travel management"/>
<FAQ category="About Accxchange"/>
<Footer segment="Smarty Home"/>
    </>
  );
}

export default ExpenseTravelManagement;
